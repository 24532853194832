import styled from "@emotion/styled"
import tw from "twin.macro"

import { P } from "../Styled/Text"

import Next from "static/icons/theme/chevron-right.svg"
import Prev from "static/icons/theme/previous.svg"

export const Crumbs = tw.nav`flex items-center justify-start`
export const Crumb = styled(P)`
  ${props =>
    props.singular
      ? tw`flex items-start truncate md:text-grey-darker hover:text-dark duration-100`
      : tw`md:text-grey-darker hover:text-dark duration-100`}
`
export const IconNext = tw(Next)`block w-2 h-2 md:text-grey-darker`
export const IconPrev = tw(Prev)`block w-2 h-2 mr-0-6`
