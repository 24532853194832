import { Link } from "gatsby"
import styled from "@emotion/styled"
import tw from "twin.macro"

import { Container } from "../Styled/Container"
import { H1, H3, H5, P } from "../Styled/Text"
import { TextButton } from "../Styled/Button"

export const StyledContainer = tw(Container)`max-w-lg`
export const StyledH1 = tw(H1)`mx-auto text-xl md:text-lg mt-5 mb-5 font-sans uppercase tracking-tense text-center`
export const FiltersWrapper = styled(Container)`
  ${tw`sticky min-h-4-4 md:min-h-3-2 top-0 z-20`}
`
export const Filters = styled.div`
  ${tw`h-full flex items-center justify-between py-1 md:px-0-6 md:px-2-2 mx-auto max-w-lg relative`}
  ${props => (props.inverted ? tw`bg-dark text-light` : tw`bg-light text-dark`)}
`
export const FiltersBorder = styled.div`
  ${tw`border-t absolute left-0 right-0 top-0 md:mx-2-2`}
  border-color: #eeeeee
`
export const FilterColumn = styled.div`
  ${tw`w-1/2 items-center`}
  ${props => (props.end ? tw`justify-end` : props.center ? tw`justify-center -mx-30` : tw`justify-start`)}
  ${props => (props.desktop ? tw`hidden md:flex` : props.mobile ? tw`flex md:hidden` : tw`flex`)}
`
export const FilterText = styled(P)`
  ${tw`text-xs tracking-wider uppercase font-medium pr-0-8`}
  ${props => (props.grey ? tw`text-grey-darker` : tw``)}
`
export const Filter = styled(TextButton)`
  ${tw`text-xs tracking-wider uppercase px-0-8 hover:text-dark hover:no-underline`}
  ${props => (props.inverted ? tw`text-light` : tw``)}
  ${tw`text-grey-darker font-medium`}
  ${props => (props.active ? tw`text-dark` : ``)}
`

export const GridContainer = tw(Container)`pb-5 md:pb-5 max-w-lg`
export const Card = tw.div`w-full md:w-1/3 md:px-0-6 mb-1-6 md:mb-3`
export const StyledLink = tw(Link)`block transition-all duration-200 hover:opacity-70`
export const ImageWrapper = tw.div`mb-1-6`
export const Desktop = tw.div`hidden md:block`
export const Mobile = tw.div`block md:hidden`
export const StyledH5 = tw(H5)`font-sans tracking-normal leading-relax`
export const StyledP = tw(P)`mb-0-4 leading-looser tracking-relaxed`

export const Section = tw.section`w-full flex flex-wrap md:flex-no-wrap items-start justify-start pb-1-2 md:pb-12`
export const FeaturedImage = tw.div`w-full md:w-1/2 relative md:sticky top-0`
export const ArticleContent = tw.div`w-full max-w-full px-1-2 md:w-72-6 md:px-3-2 lg:px-6 md:pt-8-8 -mb-3-2`
export const BreadcrumbWrapper = tw.div`mt-1-2 md:mb-1-2 md:mt-0`
export const Details = tw.div`mt-1-2 md:mt-0 mb-5-6 md:mb-8`
export const ImageLink = styled.div`
  ${tw`block focus:outline-none`}
  ${props => (props.as === `button` ? tw`cursor-pointer` : ``)}
`
export const ContentBlock = tw.div`mb-3-2 relative`
export const Wishlist = styled.div`
  ${tw`absolute top-0 right-0 pr-1-2 pt-1-2 md:opacity-0 group-hover:opacity-100 transition-all duration-200`}
  ${props => (props.colour === `Dark` ? tw`text-dark` : tw`text-light`)}
`
export const Caption = styled(P)`
  ${tw`text-xs leading-loosest absolute bottom-0 left-0 max-w-full pb-1-6 pl-1-6`}
  ${props => (props.colour === `Dark` ? tw`text-dark` : tw`text-light`)}
`
export const StyledH3 = tw(H3)`mb-2-4 uppercase font-sans`
export const CategoryWrapper = tw.div`w-full flex justify-between`
