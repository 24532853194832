import React from "react"

import { withWishlistPin } from "./withWishlistPin"
import { PinItButton, PinItButtonIcon } from "../WishlistStyles"

export const WishlistPin = withWishlistPin(({ handleClick, pinterest }) => (
  <PinItButton data-pin-custom={pinterest?.dataPinCustom} onClick={event => handleClick(event)}>
    <PinItButtonIcon />
  </PinItButton>
))
