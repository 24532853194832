import React from "react"
import { navigate } from "gatsby"

import { withArticle } from "./withArticle"
import { Breadcrumb } from "../Breadcrumb/Breadcrumb"
import { Image } from "../Image/Image"
import { RichText } from "../RichText/RichText"
import { WishlistButton } from "../Wishlist/Button/WishlistButton"
import { WishlistPin } from "../Wishlist/Pin/WishlistPin"
import { P } from "../Styled/Text"
import {
  Section,
  ArticleContent,
  FeaturedImage,
  BreadcrumbWrapper,
  Details,
  StyledH3,
  ContentBlock,
  Caption,
  ImageLink,
  Wishlist,
} from "../Article/ArticleStyles"

interface Props {
  breadcrumbs: any
  content: any
  image: string
  lists: any
  paths: Array<any>
  url: string
}

export const Article = withArticle(({ content, image, lists, paths }: Props) => (
  <>
    <Section>
      <FeaturedImage>
        <Image src={image} />
      </FeaturedImage>

      <ArticleContent>
        <BreadcrumbWrapper>
          <Breadcrumb paths={paths} />
        </BreadcrumbWrapper>

        {content?.categories?.length > 0 || content?.publishedAt ? (
          <Details>
            {content?.categories?.length > 0 ? <P>{content.categories[0]}</P> : null}
            {content?.publishedAt ? <P>{content.publishedAt}</P> : null}
          </Details>
        ) : null}

        <StyledH3>
          <RichText content={content.title} />
        </StyledH3>

        {content?.content?.map(item =>
          item.type === `image` ? (
            <ContentBlock key={item.key}>
              <ImageLink
                as={item?.link?.url ? `button` : null}
                className={`group`}
                onClick={item?.link?.url ? () => navigate(item.link.url) : null}
                title={item?.link?.title || item.alt}
              >
                <Image src={item} alt={item.alt} />
                {item.caption ? <Caption colour={item.colour}>{item.caption}</Caption> : null}
                <Wishlist colour={item.colour}>
                  <WishlistButton
                    item={{
                      id: item.key || item.asset?.id,
                      image: {
                        ...item.asset,
                        ...item.fluid,
                        alt: item.alt,
                      },
                      link: item.link || null,
                      title: item.caption || item.alt || null,
                      type: item.type || null,
                    }}
                    wishlistName={lists[1].key}
                  />
                  <WishlistPin item={{ ...item, src: item.fluid ? item.fluid.src : null }} />
                </Wishlist>
              </ImageLink>
            </ContentBlock>
          ) : (
            <ContentBlock key={item.key}>
              <P>
                <RichText content={item} />
              </P>
            </ContentBlock>
          )
        )}
      </ArticleContent>
    </Section>
  </>
))
