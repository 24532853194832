import React from "react"
import { graphql } from "gatsby"

import { Article as Template } from "@components/Article/Article"

export const pageQuery = graphql`
  query($id: String) {
    page: sanityArticle(_id: { eq: $id }) {
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      _createdAt
      author {
        name
      }
      categories: _rawCategories(resolveReferences: { maxDepth: 10 })
      title: _rawTitle(resolveReferences: { maxDepth: 10 })
      _rawContent: _rawContent(resolveReferences: { maxDepth: 10 })
      publishedAt(formatString: "DD.MM.YYYY")
      featuredImage: _rawFeaturedImage(resolveReferences: { maxDepth: 10 })
    }
  }
`

export default ({ data, ...props }) => <Template {...props} {...data} />
