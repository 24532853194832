import React from "react"

import { useApp } from "@hooks/useApp"

export const withWishlistPin = Component => ({ name = `WishlistPin`, item }) => {
  const {
    config: {
      settings: { pinterest },
    },
  } = useApp()

  const handleClick = event => {
    event.stopPropagation()

    window.PinUtils.pinOne({
      media: item.src,
      description: item.caption,
    })
  }

  Component.displayName = name
  return <Component handleClick={handleClick} pinterest={pinterest} />
}
