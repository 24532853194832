import React, { Fragment } from "react"
import { Link } from "gatsby"

import { Crumbs, Crumb, IconNext, IconPrev } from "./BreadcrumbStyles"

interface Props {
  paths: Array<any>
}

export const Breadcrumb = ({ paths = [] }: Props) => (
  <Crumbs>
    {paths?.map(({ title, url }, i) => (
      <Fragment key={title}>
        {i ? <IconNext /> : null}
        {url ? (
          <Crumb as={Link} singular={paths.length === 1} to={url}>
            {paths.length === 1 ? <IconPrev /> : null}
            {title}
          </Crumb>
        ) : (
          <Crumb>{title}</Crumb>
        )}
      </Fragment>
    ))}
  </Crumbs>
)
